<template>
  <div style="padding-bottom: 200px">
    <RocketLoad
      :full="true"
      v-if="isLoading"
    />

    <template v-else>
      <div class="grid align-items-center justify-content-between">
        <div class="flex pb-3">
          <h1>
            <template>Invoice Detail</template>
          </h1>

        </div>
      </div>
      <Divider />
      <form
        @submit.prevent="submitHandler"
        v-form-enter
      >
        <div class="p-fluid formgrid grid">
          <div class="field col-12 sm:col-6">
            <label class="label">Invoice Description</label>
            <div class="p-inputgroup">
              <InputText
                placeholder="Invoice Description"
                v-model="name"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-3">
            <label class="label">Reference</label>
            <div class="p-inputgroup">
              <InputText
                mode="decimal"
                v-model="client_reference"
                placeholder="Reference"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-3">
            <label class="label">Terms </label>
            <div class="p-inputgroup">
              <Dropdown
                v-if="!isLoading && (terms === 'Cash on Delivery' ||terms === '14 Days' || terms === '30 Days' || terms === '60 Days')"
                placeHolder="Payment Terms"
                :options="clientTerms"
                v-model="terms"
                optionLabel="description"
                optionValue="uid"
              />
              <span
                class="p-input-icon-right"
                v-else-if="!isLoading"
              >
                <InputText
                  placeholder="Custom Terms"
                  v-model="terms"
                  style="width: 100%;"
                />
                <i
                  class="las la-times cursor-pointer"
                  @click="terms = 'Cash on Delivery'"
                ></i>
              </span>
              <Skeleton
                height="100%"
                borderRadius="6px"
                v-else
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label class="label">Invoice Type</label>
            <div class="p-inputgroup">
              <Dropdown
                placeholder="Select Billing Option"
                :options="billingOptions"
                v-model="selectedBillingOption"
                optionLabel="label"
                optionValue="value"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label class="label">% Of Each Item</label>
            <div class="p-inputgroup">
              <InputNumber
                :max="100"
                :disabled="disableProgressPercentage"
                placeholder="Percentage to invoice"
                v-model="progress_percentage"
              />
            </div>
          </div>
          <div class="field col-12">
            <template v-if="selectedBillingOption=='remaining'"> <i class="las la-exclamation-circle"></i> <label class="label">Note: All Tasks and Costs will be marked completed and a Draft final invoice created </label></template>
          </div>
          <div class="field col-12 sm:col-6">
            <label class="label">Issue date </label>
            <Calendar
              placeholder="Issue date"
              v-model="date_issued"
              id="date"
              :showIcon="true"
              dateFormat="yy-mm-dd"
            />
          </div>
          <div class="field col-12 sm:col-6">
            <label class="label">Payment date </label>
            <Calendar
              disabled
              v-model="date_due"
              id="date"
              :showIcon="true"
              dateFormat="yy-mm-dd"
            />
          </div>

          <div class="col-12">
            <div class="field">
              <label
                for="Description"
                class="label"
              >Description </label>
              <Textarea
                rows="5"
                v-model="description"
                placeholder="Description"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-content-between">
          <Button
            type="submit"
            :label="(!this.selectedObject ? 'Create' : 'Update') + ' Invoice'"
            :input-class="['is-primary']"
          />
          <Button
            class="p-button-text p-button-secondary"
            @click="clearSliderContent"
          >
            CANCEL
          </Button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { state } from "../../../services/data_service";

export default {
  name: "InvoiceSlider",
  components: {},
  data() {
    return {
      submitted: false,
      isLoading: false,
      name: null,
      description: null,
      client_reference: null,
      reference: null,
      date_issued: new Date(),
      date_due: null,
      progress_percentage: 50, // Default to 50%
      terms: null,
      billingOptions: [
        { label: "Bill items in full", value: "full" },
        { label: "Bill items partially", value: "partial" },
        { label: "Bill All Remaining & Close Job", value: "remaining" },
      ],
      selectedBillingOption: "partial", // Default billing option
      disableProgressPercentage: false,
    };
  },
  validations: {
    // Add explicit validations here
    name: { required },
    progress_percentage: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(100),
    },
    date_issued: { required },
    terms: { required },
    description: { required },
    selectedBillingOption: { required },
  },
  beforeDestroy() {
    this.clearSliderContent();
  },
  mounted() {
    if (this.selectedObject) {
      var invoice = JSON.parse(JSON.stringify(this.selectedObject));
      this.name = invoice.name;
      this.description = invoice.description;
      this.client_reference = invoice.client_reference;
      this.progress_percentage = invoice.progress_percentage;

      this.date_due = this.$moment(invoice.date_due).format("YYYY-MM-DD");
      if (invoice.date_issued) {
        this.date_issued = this.$moment(invoice.date_issued).format(
          "YYYY-MM-DD"
        );
      }
    }
    this.terms = this.clientTerms[0].uid;
    this.name = this.job?.title;
    if (this.selectedObject?.final_invoice) {
      this.selectedBillingOption = "remaining";
    }
  },
  methods: {
    async submitHandler() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Please complete all required fields correctly.", // More general message
          life: 3000,
        });
        return; // Stop execution if validation fails
      }

      this.isLoading = true;

      const url =
        process.env.VUE_APP_ROOT_API +
        "/v1/finance/invoice" +
        (this.selectedObject ? "/" + this.selectedObject.uid : "") +
        (this.selectedBillingOption === "remaining" ? "?final_invoice=1" : "");

      const invoiceData = {
        name: this.name,
        description: this.description,
        client_reference: this.client_reference,
        progress_percentage: Number(this.progress_percentage), // Ensure it's a number
        date_issued: this.buildShortDate(new Date(this.date_issued)),
        date_due: this.buildShortDate(new Date(this.date_due)),
        job: this.$route.params.uid,
        terms: this.terms,
      };

      try {
        const response = await this.$axios.post(url, invoiceData, {
          headers: { "Content-type": "application/json" },
        });

        // Success!
        this.$toast.add({
          severity: "success",
          summary: "Invoice Saved",
          detail: this.selectedObject
            ? "Invoice updated successfully."
            : "Invoice created successfully.",
          life: 3000,
        });
        this.clearSliderContent();
        this.$emit("invoice-saved", response.data); // emit event
      } catch (error) {
        console.error("Invoice submission error:", error);

        let errorMessage = "An unexpected error occurred."; // Default error message

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            errorMessage = "Invalid data submitted."; // More specific error
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized. Please log in again.";
          } else if (error.response.status === 404) {
            errorMessage = "Invoice not found";
          } else if (error.response.status === 422) {
            // Assuming 422 is used for validation errors on the server-side
            errorMessage = "Server validation error: ";

            if (error.response.data.errors) {
              const errorFields = Object.keys(error.response.data.errors);

              errorMessage += errorFields
                .map((field) => error.response.data.errors[field].join(", "))
                .join("; ");
            } else {
              errorMessage +=
                error.response.data.message || "Please check the form data.";
            }
          } else if (error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message; // use back-end error message
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = "Network error. Please check your connection.";
        }

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: errorMessage,
          life: 5000, // Longer duration for errors
        });
      } finally {
        this.isLoading = false;
      }
    },
    addDays(date, days) {
      const newDate = new Date(date);
      const numDays = parseInt(days.split(" ")[0]);
      newDate.setDate(newDate.getDate() + numDays);
      return newDate;
    },
  },
  computed: {
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    job() {
      return state.job;
    },
    clientTerms() {
      return state.clientTerms;
    },
  },
  watch: {
    terms(n) {
      if (n === "Cash on Delivery") {
        this.date_due = this.addDays(this.date_issued, "0 days");
        return;
      }

      if (n?.toLowerCase().includes("days")) {
        const days = n.split(" ")[0];
        this.date_due = this.addDays(this.date_issued, days);
        return;
      }

      this.date_due = this.addDays(this.date_issued, "30 days");
    },
    date_issued(n) {
      if (this.terms === "Cash on Delivery") {
        this.date_due = this.addDays(n, "0 days");
        return;
      }
      if (this.terms?.toLowerCase().includes("days")) {
        const days = this.terms.split(" ")[0];
        this.date_due = this.addDays(n, days);
        return;
      }

      this.date_due = this.addDays(n, "30 days");
    },
    selectedBillingOption(newVal) {
      if (newVal === "full" || newVal === "remaining") {
        this.progress_percentage = 100;
        this.disableProgressPercentage = true;
      } else if (newVal === "partial") {
        this.progress_percentage = 50;
        this.disableProgressPercentage = false;
      }
    },
    //watch for progress_percentage changes
    progress_percentage(newVal) {
      if (newVal !== 100 && this.selectedBillingOption !== "partial") {
        this.selectedBillingOption = "partial";
        this.disableProgressPercentage = false;
      }
    },
  },
};
</script>